@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  margin-top: 22px;
}

.profilePopup {
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  color: colors.$default-text;

  @media screen and (min-width: vars.$extra-large) {
    height: auto;
  }

  header {
    background: transparent;
    border-bottom: 0;
    font-stretch: condensed;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    button {
      color: colors.$default-text;
    }
  }
}

.register {
  margin-top: 30px;
  text-align: center;

  p {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
  }
}

.user {
  align-items: center;
  border-bottom: 1px solid colors.$grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: vars.$gutter 0;

  svg {
    max-width: 128px;
    max-height: 128px;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding-bottom: 15px;

    h3 {
      font-size: 1.2rem;
    }
  }

  img {
    border-radius: 50%;
    margin-bottom: 15px;
    width: 50%;
    max-width: 128px;

    @media screen and (min-width: vars.$extra-large) {
      max-height: 150px;
      max-width: 150px;
    }
  }
}

.menuItems {
  margin-top: 30px;
  margin-bottom: 30px;

  > a {
    align-items: center;
    color: colors.$default-text;
    display: flex;
    font-size: 1.125rem;
    padding: 10px 0;
    position: relative;
    text-decoration: none;

    svg {
      font-size: 16px;
      margin-right: 15px;
    }

    span {
      background-color: colors.$secondary-text;
      border-radius: 10px;
      color: colors.$white;
      font-size: 0.75rem;
      height: 20px;
      line-height: 20px;
      position: absolute;
      right: 0;
      text-align: center;
      vertical-align: middle;
      width: 25px;
    }
  }
}

.button {
  margin: vars.$gutter 0;

  @media screen and (min-width: vars.$extra-large) {
    margin-bottom: 0;
  }
}

.registerBtn:hover {
  background-color: colors.$secondary-color !important;
  box-shadow: none;
  color: colors.$white !important;
}

.privacyAgreement {
  font-stretch: condensed;
  //styleName: Caption/Large;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin: 8px 0 12px 0;
}
